/* DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU*/
*/

/* .TTM-Container-Div {
    position: absolute;
    width: fit-content;
    height: fit-content;
}
.TTM-Main-Wrapper-DIF {
    display: flex;
    flex-direction: column;
    background-color: rgb(30, 30, 30);
    border: solid rgb(75, 75, 75) 1px;
    border-radius: 8px;
    width: 110px;
    color: rgb(214, 214, 214);
    font-size: small;
    position: absolute;
    bottom: 45%;
    padding: 0;
}
.TTM-Btn-Wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(75, 75, 75);
    padding: 5px 0px 5px 0px;
    cursor: pointer;
}
.TTM-BTN-Title{

} */
/* DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU DEFAULT MENU*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/*|||*/
/* PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU*/
.POM-Playlist-Wrapper {
    /* flex-direction: column;
    background-color: rgb(30, 30, 30);
    border: solid rgb(75, 75, 75) 1px;
    border-radius: 8px;
    width: 200px;
    color: rgb(214, 214, 214);
    font-size: small;
    position: relative;
    left:-100%;
    margin-bottom: 158px;
    padding: 0;
    z-index: 100; */
}

.POM-BTN-Wrapper-Title {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgb(75, 75, 75);
    padding: 5px 0px 5px 0px; */
}

.POM-BTN-Wrapper {
    /* display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(75, 75, 75);
    padding: 5px 0px 5px 0px;
    cursor: pointer; */
}

/* PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU PLAYLIST MENU*/

.TT-Menu-Wrapper{
    position: absolute;
    margin-right:70px;
    border-radius: 5px;
    z-index: 100;
    font-size: .9em;
    border: solid rgb(75, 75, 75) 1px;
    background-color: rgb(45, 45, 45);
    color: white;
}

.TT-Options-Wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 110px;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.TT-Options-Wrapper > *:hover{
    /* background-color: rgb(75, 75, 75);; */
    text-shadow: 1px 1px 2px pink;
}

.TT-Option-Container{
    cursor: pointer;
    padding: 5px 5px 5px 5px;
}






.TT-PL-Wrapper{
    width: 180px;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
}

.TT-PL-Wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.TT-PL-Title{
    display: flex;
    justify-content: space-between;
    padding: 0 10px 5px 5px;
    margin-bottom:5px;
    font-size: 1.2em;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid rgb(75, 75, 75);
}

#TT-PL-Arrow{
    padding-top:2px;
    font-size: 1em;
    cursor: pointer;
}

.TT-PL-Option{
    padding: 0 5px 0 5px;
    font-size: 1em;
    font-weight: 300;
    cursor: pointer;
    height: 35px;
    overflow-x: scroll;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.TT-PL-Option::-webkit-scrollbar{
    width: 0;
    height: 0;
    background: transparent;
}

.TT-PL-Option:hover{
    background-color: rgb(75, 75, 75);
}