.DS-Main-Wrapper{
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 200px;
    padding: 0px 0px 15px 0px;
    background-color: rgb(51, 46, 50);
    font-size: medium;
    border: 1px solid rgb(100, 91, 101);
}


.DS-Icon{
    position: absolute;
    top:35px;
    left: 105px;
}

.DS-Title{
    color: white;
    position: absolute;
    font-size: .8em;
    width: 250px;
    text-align: center;
    font-weight: 600;
    top: 100px;
    left: 5px;
}

.DS-Buttons{
    display: flex;    
    flex-direction:row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: .9em;
    position: absolute;
    bottom: 15px;
    left: 12px;
}

.DS-Cancel-Button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: rgb(112, 102, 115);
    /* border: 1px solid white; */
    width: 115px;
    border-radius: 6px;
    padding: 6px 0px 6px 0px;
    color: white;
    cursor: pointer;
}

.DS-Del-Button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: rgb(191, 30, 34);
    /* border: 1px solid white; */
    width: 115px;
    border-radius: 6px;
    padding: 6px 0px 6px 0px;
    color: white;
    cursor: pointer;
}