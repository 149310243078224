.UP-Main-Wrapper{
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  gap: 2px;
  border-radius: 5px;
  padding: 0px 50px 0px 10px;
  /*margin: 0 30px 0 30px; */
}

.UP-Title{
  display: flex;
  align-self: center;
  gap: 5px;
  color: white;
  font-size: 30px;
}

.UP-Title-Input {
  resize: none;
}

.UP-Title-Input-Wrapper{
  display: flex;
  flex-direction: column;
}

.UP-Description-Input-Wrapper{
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.UP-Description-Input{
  height: 20px;
  width: 300px;
  display: flex;
  align-self: center;
}

.UP-Tag {
  display: flex;
  color: white;
  /* margin-top: 0; */
}

.UP-Submit-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: rgb(191, 30, 34);
  width: 115px;
  border-radius: 6px;
  padding: 6px 0px 6px 0px;
  color: white;
  cursor: pointer;
  transition: .2s ease-in;
}

.UP-Submit-Button:hover {
  box-shadow: 3px 3px 3px black;
}
