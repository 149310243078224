.SF-Wrapper {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.SF-Song-Title-Lable {
    display: flex;
    flex-direction: column;
}

.SF-Title {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    color: rgba(245, 245, 247, 1);
    padding-left: 10px;
}

.SF-Form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
    color: rgba(245, 245, 247, 1);
}

.SF-Form-Top-Text {
    display: flex;
    flex-direction: column;
}

.SF-Submit-Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: rgb(218, 15, 72);
    width: 60%;
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    border: 0 solid;
    color: white;
    cursor: pointer;
}

.SF-Submit-Btn:disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: grey;
    width: 60%;
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    border: 0 solid;
    color: white;
    cursor: not-allowed;
}

.SF-Submit-Btn:hover {
    box-shadow: 5px 5px 5px black;
}

.SF-Genre {
    color: black;
}

.SF-Loading-GIF-Container {
    display: flex;
    justify-content: center;
}

.SF-Loading-GIF {
    width: 10%;
    height: auto;
}

.hidden {
    visibility: hidden;
}

#form-input:focus {
    border: 2px solid red;
}

.SF-Errors {
    color: red;
}



.SF-Wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    padding-bottom: 40px;
}

#x {
    position: absolute;
    top: 25px;
    right: 25px;
}

.SF-Song-Title-Lable {
    display: flex;
    flex-direction: column;
}

.SF-Song-Title{
    height: 25px;
    font-size: 1em;
    margin-top:10px;
    text-align: left;
    padding-bottom: 0px ;
}

.SF-Title {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    color: rgba(245, 245, 247, 1);
    padding-left: 10px;
}

.SF-Form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    color: rgba(245, 245, 247, 1);
}

.SF-Form-Top-Text {
    display: flex;
    flex-direction: column;
}

.SF-FileWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}



.SF-Genre {
    color: black;
}

.SF-Loading-GIF-Container {
    display: flex;
    justify-content: center;
}

.SF-Loading-GIF {
    width: 10%;
    height: auto;
}

.hidden {
    visibility: hidden;
}

#form-input:focus {
    border: 2px solid red;
}

.SF-Errors {
    color: red;
}

.DragAndDropContainer {
    border: 2px dashed #aaa;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background-color: rgb(75, 75, 75);
    cursor: pointer;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Song-InnerBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.CoverImage-InnerBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#CI-Cloud {
    font-size: 3em;
}



.DragAndDropContainer:hover {
    border-color: #888;
}

.DragAndDropContainer p {
    margin: 0;
    font-size: 16px;
    color: #888;
}

.DragAndDropContainer.active {
    background-color: #eef5fc;
}

.SF-CoverImageFileInput-Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#SF-CoverImageFileInput{
    border: 2px dashed #aaa;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background-color: rgb(75, 75, 75);
    cursor: pointer;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CoverImgPreview {
    width: 70px;
    max-height: 100px;
}

.SF-SongInput-Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

#SF-SongInput{
    border: 2px dashed #aaa;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background-color: rgb(75, 75, 75);
    cursor: pointer;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SongPreview {
    display: flex;
    align-items: center;
    justify-self: center;
}