.M-NB-Body {
    display: flex;
    /* background-color:red; */
    opacity: 0.98;
    height: 128px;
    width: 100vw;
    max-width: 100vw;
    z-index: 10;
    position: fixed;
    bottom: 0px;
    left: 0px;
    gap: 20px;
    flex-direction: column;
    overflow: hidden;
}


/* .fa-gear {
    color: white;
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 1.5em;
} */

.M-NB-Player-Wrapper-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.M-NB-Player-Wrapper {
    display: flex;
    background-color: green;
    height: 57px;
    width: 90vw;
    border-radius: 10px;
    align-self: center;
    align-items: center;
}


.M-NB-SongInfo-Wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.M-NB-SongImg-Wrapper {
    margin-left: 10px;
    width: 44px;
    height: 44px;
}

.M-NB-SongImg {
    width: 44px;
    height: 44px;
    border-radius: 5px;

}

.M-NB-SongTitle-Wrapper {
    padding-top: 5px;
}

.M-NB-SongTitle {
    font-size: 1em;
    font-weight: 500;
    color: white;
}

.M-NB-SongArtist {
    font-size: .8em;
    color: rgb(239, 239, 239);
}

.M-NB-Buttons-Wrapper {
    position: absolute;
    right: 10%;
    padding-bottom: 5px;
}


.P-NB-ProgressBar-Wrapper {
    position: relative;
    bottom: 22px;
    background-color: transparent;
    width: 88vw;
    left: 2px;
    height: fit-content;
    z-index: 1;
    overflow: hidden;
}

.M-NB-ProgressBar-Wrapper {
    position: relative;
    bottom: 15px;
    background-color: transparent;
    width: 88vw;
    left: 2px;
    height: fit-content;
    z-index: 1;
    overflow: hidden;
}


.M-NB-Nav {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #000;
    color: #fff;
}

.M-NB-Icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.M-NB-Icon p {
    margin: 0;
    font-size: 12px;
}

#M-Search {
    font-size: 28px;
}

.material-symbols-outlined.active {
    font-variation-settings: 'FILL' 1;
}