.BR-body {
  z-index: -1;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
  max-width: 100vw;
  overflow: hidden;
}


@media only screen and (max-width: 1050px) {
  .BR-body {
    z-index: -1;
    padding-top: 0px;
    padding-left: 3%;
    padding-right: 3%;
    align-items: center;
    height: 100%;
    padding-bottom: 100px;
    max-width: 100vw;
    overflow: hidden;
  }

  .mySwiper-BR {
    height: 320px;
  }
}


.BR-browse-container {
  display: flex;
  justify-content: space-evenly;
}

.BR-images {
  margin-right: 2rem;
  border-radius: 10px;
  width: 35vw;
  max-width: 200px;
  box-shadow: 3px 3px 5px black;
  transition: box-shadow 0.3s ease-in-out;
  object-fit: cover;
  opacity: 1;
  transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 1000px) {
  .BR-images {
    display: flex;
    width: 35vw;
    max-width: 200px;
    justify-content: center;
    align-items: center;
  }
}

.BR-images:hover {
  cursor: pointer;
  box-shadow: 10px 10px 10px black;
  /* opacity: .8; */
  /* animation: up-down 1s ease-in-out infinite alternate; */
}

.mySwiper-BR {
  height: fit-content;
}

@keyframes up-down {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10px);
  }
}

/*///////////////////////////////////////////////////////////////*/



.art-cover {
  opacity: 1;
  transition: all 0.3s ease;
}

.art-cover:hover {
  cursor: pointer;
  opacity: .5;
  background-image: url('../../images/play_arrow.png');
}

.BR-Top {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 12px;
}

.BR-labels {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1.4px solid rgb(54, 54, 54);
}

.BR-browse-images {
  border-radius: 12px;
  height: 275px;
  width: 250px;
  margin-left: 40px;
}

.BR-album-images {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-left: 60px;
  padding: 0;
}



.BR-album-images:hover {
  cursor: pointer;
}

.BR-song-container-div {}

.BR-song-grid-container-wrapper {}

.BR-song-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
}

.BR-song-section {
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 50px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.BR-song-container-div {
  border-top: 1px solid rgb(54, 54, 54);
  /* margin-right: 40px; */
  padding-right: 40px;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.BR-song-images {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 40px;
}

.BR-song-title {
  margin-top: 10px;
  margin-left: 10px;
}

#song-name {
  font-weight: 300;
  /* font-size: .5em; */
}



.BR-album-title {
  color: rgb(238, 238, 238);
  font-size: 12px;
  width: fit-content;
  margin-left: 60px;
}




@media only screen and (max-width: 1400px) {
  .BR-song-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0px;
    justify-items: center;
  }
  .BR-labels{
    margin-bottom: 0px;

  }
}

@media only screen and (max-width: 768px) {
  .BR-song-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;

    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
  }

  .BR-song-section {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: none;
    padding-right: 0px;
    text-align: left;
    padding-left: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }

  .BR-labels {
    margin-bottom: 0;
  }

  .M-icon-section {
    position: relative;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    width: 10px;
  }

  #BR-icons-wrap {
    width: fit-content;
  }
}

.BR-heart-icon {
  color: rgb(226 96 106);
}

#BR-song-icon-heart {
  color: rgb(226 96 106);
}


.BR-heart-icon:hover {
  cursor: pointer;
  color: rgb(226 96 106);
}

.BR-Song-heart-icon {
  color: rgb(226 96 106);
  cursor: pointer;
}

#BR-song-icon-heart {
  cursor: pointer;
}

#song-icon-heart:hover {
  cursor: pointer;
}

#song-icon-menu:hover {
  cursor: pointer;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: rgb(222, 222, 222);
}







@media only screen and (max-width: 700px) {
  .BR-album-title {
    color: rgb(238, 238, 238);
    font-size: 9px;
    text-align: center;
    width: fit-content;
    margin-left: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .BR-album-images {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-left: 60px;
    padding: 0;
  }
}