.slide-out-menu {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
}

.slide-out-menu-bar {
    width: 100%;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
    transition: transform 0.3s, opacity 0.3s;
}

.slide-out-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 300px;
    height: 100%;
    background-color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s;
    z-index: 100;
}

.slide-out-menu-container.open {
    transform: translateX(0);
    background-color: black;
    color: white;
    font-size: 1.5em;
    z-index: 100;
}

.SOM-Loggedin {
    display: flex;
    flex-direction: column;
}

.SOM-Loggedout{
    display: flex;
    flex-direction: column;
}

.SOM-Welcome {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    text-overflow: ellipsis;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}


.slide-out-menu ul {
    list-style-type: none;
    padding-bottom: 40px;
}

.slide-out-menu p {
    list-style-type: none;
    padding: 20px;
    margin: 0px;
    
}

p.SOM-Label{
    margin-block-end: 0;
    cursor: pointer;
}

p.SOM-Demo-Label{
    margin-block-end: 0;
    color: crimson;
    cursor: pointer;
}

p.SOM-Demo-Label:hover{
    animation: slideOutAnimation 0.3s forwards;
}

p.SOM-Label:hover {
    animation: slideOutAnimation 0.3s forwards;
}

.SOM-Logout{
    cursor: pointer;
}

.SOM-Logout:hover {
    animation: slideOutAnimation 0.3s forwards;
}

@keyframes slideOutAnimation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-15px);
    }
}

.slide-out-menu li {
    margin-bottom: 10px;
}

.slide-out-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    transition: opacity 0.3s;
    z-index: 1;
}

.slide-out-menu-overlay.open {
    display: block;
}