.NB-body {
    display: flex;
    background-color: rgb(44, 44, 44);
    /* opacity: 0.91; */
    height: 54px;
    position: fixed;
    left: 260px;
    width: calc(100% - 260px);
    /* subtracts removed width from width when calculating width */
    z-index: 10;
}

.fa-solid.fa-gear {
    z-index: 100;
    position: fixed;
    top: 18px;
    right: 15px;
    font-size: 1.4em;
    color: white;
    cursor: pointer;
}

.fa-solid.fa-user-plus {
    z-index: 100;
    font-size: .8em;
    position: absolute;
    top: 12px;
    right: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(226, 96, 106, 1);
    width: 90px;
    height: 30px;
    gap: 5px;
    border-radius: 20px;
    cursor: pointer;
}

.LoginBtnNavBarText{
    font-size: 1em;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}



.music-player-container {
    display: flex;
    padding-left: 50px;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
}

#shuffle {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    color: rgb(105, 105, 105);
}

#backwards {
    display: flex;
    flex-direction: row;
    color: rgb(105, 105, 105);
}

#play {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    color: rgb(105, 105, 105);
}

#play:hover {
    cursor: pointer;
}

#forwards {
    display: flex;
    flex-direction: row;
    color: rgb(105, 105, 105);
}

#repeat {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    color: rgb(105, 105, 105);
}

#shuffle:hover {
    color: rgb(164, 159, 165);
}

#backwards:hover {
    color: rgb(164, 159, 165);
}

#play:hover {
    color: rgb(164, 159, 165);
}

#forwards:hover {
    color: rgb(164, 159, 165);
}

#repeat:hover {
    color: rgb(164, 159, 165);
}

.NB-Wrapper {
    /* wraps the song image and the grey player */
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    position: absolute;
    left: 28%;
}

.NB-MUSIC-BLOCK {
    background-color: rgba(105, 105, 105, .5);
    display: flex;
    position: relative;
    flex-direction: row;
    width: 30vw;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    /* come back */
    overflow: hidden;
}

.NB-Player-CurrentSong-Wrapper {
    font-size: .5em;
    display: flex;
    flex-direction: column;
}

.NB-Player-CurrentSong {
    display: flex;
    text-align: center;
    align-self: center;
    font-size: 1.5em;
    font-weight: 600;
    color: white;
}

.NB-Player-ArtistAlbum {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.NB-Progress {
    position: absolute;
    bottom: -6px;
    left: -3px;
    width: 102%;
    height: 0px;
    background-color: #e9e9e9;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;
}

input.NB-Progress {
    -webkit-appearance: none;
    background-color: rgb(196, 195, 198);
}

input.NB-Progress:focus {
    outline: none;
}

input.NB-Progress::-webkit-slider-runnable-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgb(196, 195, 198);
    border-radius: 1px;
    border: 0px solid #000000;
}

input.NB-Progress::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid rgb(196, 195, 198);
    height: 10px;
    width: 10px;
    border-radius: 25px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
}

.NB-Img {
    width: 44px;
    height: 40px;
    box-shadow: 2px 0 3px #000;
    /* Box shadow properties, adjust values as needed */
}

.NB-Player-Times {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: space-between;
    font-size: .55em;
    position: absolute;
    bottom: 6px;
    color: rgb(121, 120, 121);
}

.NB-Pear {
    height: 31px;
    display: flex;
    align-self: center;
    opacity: .8;
}

.NB-Volume-Slider {
    display: flex;
    flex-direction: row;
    position: relative;
    right: 10px;
}

#music.fa-solid.fa-volume-low {
    color: rgb(136, 136, 136);
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

#myslider.slider {
    -webkit-appearance: none;
    width: 100px;
    background-color: rgb(44, 44, 44);
    margin-right: 50px;
}

#myslider.slider:focus {
    outline: none;
}

#myslider.slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgb(74, 74, 74);
    border-radius: 1px;
    border: 0px solid #000000;
}

#myslider.slider::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid rgb(62, 62, 62);
    height: 10px;
    width: 10px;
    border-radius: 25px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
}

#myslider.slider:focus::-webkit-slider-runnable-track {
    /* this is it! */
    background: rgb(93, 81, 91);
}

#myslider.slider::-moz-range-track {
    /* nada */
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0px solid #000000;
}

#myslider.slider::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    /* border: 1px solid #2497E3; */
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

#myslider.slider::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

#myslider.slider::-ms-fill-lower {
    /* nada */
    background: black;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

#myslider.slider::-ms-fill-upper {
    /* nada */
    background: black;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

#myslider.slider::-ms-thumb {
    /* nada */
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

#myslider.slider:focus::-ms-fill-lower {
    /* nada */
    background: #2497E3;
}

#myslider.slider:focus::-ms-fill-upper {
    /* nada */
    background: #2497E3;
}

.NB-Menu-Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 17px;
    right: 10px;
}

.fa-solid.fa-bars {
    background-color: white;
}

#burger {
    font-size: larger;
    color: rgb(120, 120, 120);
    background-color: transparent;
}

#burger:hover {
    color: rgb(164, 159, 165);
    /* box-shadow: 0px 0px 6px 5px rgba(61, 49, 65,.5); */
    /* looks bad */
    cursor: pointer;
}

.icon-hover-pointer:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1050px) {
    .fa-solid.fa-gear {
        z-index: 100;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 1.4em;
        cursor: pointer;
    }
    .fa-solid.fa-user-plus {
        z-index: 100;
        font-size: .9em;
        position: absolute;
        top: 25px;
        right: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: rgba(226, 96, 106, 1);
        width: 100px;
        gap: 10px;
        border-radius: 20px;
        cursor: pointer;
    }
    .LoginBtnNavBarText{
        font-size: .9em;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }
    #play {
        color: #fffffff7;
    }
    #play:hover {
        color: #fffffff7;
    }
}