.MD-body {
  z-index: -1;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
}

.MD-label {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(42, 42, 42);
}

.MD-Title-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MD-Title {
  width: 90vw;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  color: white;
  opacity: .9;
  font-weight: 500;
  /* border-bottom: .5px solid grey; */
}

.MD-sub-labels {
  color: rgb(238, 238, 238);
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(42, 42, 42);
}

.MD-swiper-slide-songs {
  display: inline-flex;
  justify-content: space-evenly;
}

.MD-song-images {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}


.MD-song-description-div {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.MD-song-description-div>h3 {
  padding: 0;
  margin: 0;
  color: rgb(238, 238, 238);
  font-size: 18px;
}

.MD-song-description-div>p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: rgb(105, 105, 105);
}


.MD-song-container-div {
  display: inline-flex;
  /* margin-left: 60px; */
}

#MD-eclipse {
  padding-bottom: 0px;
  padding-left: 0px;
  color: rgb(226, 96, 106)
}

#MD-eclipse:hover {
  cursor: pointer;
}

.MD-album-images {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  margin-left: 60px;
}

.MD-album-images {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-top: 50px;
  margin-left: 60px;
}

.MD-album-images:hover {
  cursor: pointer;
}


.MD-section-container {
  margin-bottom: 50px;

}

.MD-display-none {
  display: none;
}

.MD-Playlist-Info {
  display: inline-flex;
}

.MD-Playlist-CoverImage {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  margin-left: 60px;
}

#MD-Main-Wapper {
  position: absolute;
  right: -250px;
}

.MD-playlist-Title {
  color: rgb(238, 238, 238);
  font-size: 12px;
  font-weight: 300;
  width: fit-content;
  margin-left: 60px;
  max-width: 130px;
}

.MD-Playlist-Icon {
  position: absoute;
  padding-top: 10px;
  right: 65px;
}

.MD-Album-Title {
  color: rgb(238, 238, 238);
  font-size: 12px;
  font-weight: 300;
  width: fit-content;
  margin-left: 60px;
}

#MD-eclipse-playlist {
  /* padding-top: 15px; */
  padding-left: 10px;
  color: rgb(226, 96, 106)
}

#MD-eclipse-playlist:hover {
  cursor: pointer;
}


.TTM-Main-Wrapper {
  background-color: rgb(30, 30, 30);
  border: solid rgb(75, 75, 75) 1px;
  border-radius: 8px;
  width: fit-content;
  color: rgb(214, 214, 214);
  font-size: small;
}


.UDM-Main-Wrapper {
  background-color: rgb(30, 30, 30);
  /* background-color: red; */
  border: solid rgb(75, 75, 75) 1px;
  border-radius: 8px;
  width: 85px;
  color: rgb(214, 214, 214);
  font-size: small;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* used to fix overflow issue with menu popup being hidden by swiper */
div.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  overflow: none;
}

.UDM-Main-Wrapper-Hidden {
  display: none;
}

.UDM-Update-Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85px;
  /* border-bottom: solid rgb(98, 98, 98) 1px; */
  cursor: pointer;
  /* justify-content: space-between; */
  padding: 5px 0 5px 0;
  margin-left: 15px;
}

.UDM-Delete-Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 5px 0 5px 0;
  margin-left: 15px;
}

#update-ico {
  position: relative;
  left: 9.5px;
}

#delete-ico {
  position: relative;
  left: 9.5px;
}

#MD-Label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  font-size: 2.6em;
}

.MD-Song-Section-Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (max-width: 1050px) {
  .MD-body {
    z-index: -1;
    height: 100%;
    padding-bottom: 100px;
    margin: 0;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }

  #MD-Label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
    font-size: 2.6em;
    padding-left: 20px;
    max-width: 90vw;
  }

  .MD-label {
    color: rgb(238, 238, 238);
    font-size: 34px;
    font-weight: 500;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(42, 42, 42);
  }

  .MD-Title-Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MD-Title {
    width: 90vw;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    color: white;
    opacity: .9;
    font-weight: 500;
    /* border-bottom: .5px solid grey; */
  }

  .MD-Song-Section-Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .MD-playlists-container-div {
    /* display: flex;
    flex-wrap: wrap; */
  }


  .MD-sub-labels {
    color: rgb(238, 238, 238);
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(42, 42, 42);
  }

  .MD-swiper-slide-songs {
    display: inline-flex;
    justify-content: space-evenly;
  }

  .MD-song-images {
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }

  .MD-song-description-div {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  .MD-song-description-div>h3 {
    padding: 0;
    margin: 0;
    color: rgb(238, 238, 238);
    font-size: 18px;
  }

  .MD-song-description-div>p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: rgb(105, 105, 105);
  }

  .swiper-slide.MD-songs-carousel-images-div {
    /* max-width: 200px; */
    /* background-color: red; */
    /* display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MD-song-container-div {
    display: flex;
    /* margin-left: 60px; */
    width: 100px;
  }

  .MD-Playlist-Info {
    background-color: red;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    max-width: 200px;
  }

  .MD-Playlist-Icon {
    position: relative;
    padding-top: 10px;
    right: 0px;
  }

  #MD-eclipse {
    padding-bottom: 0px;
    padding-left: 0px;

    color: rgb(226, 96, 106)
  }

  #MD-eclipse:hover {
    cursor: pointer;
  }

  .MD-album-images {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin-left: 60px;
  }

  .MD-album-images {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    margin-top: 50px;
    margin-left: 60px;
  }

  .MD-album-images:hover {
    cursor: pointer;
  }


  .MD-section-container {
    margin-bottom: 50px;

  }

  .MD-display-none {
    display: none;
  }

  .MD-Playlist-Info {
    display: inline-flex;
    max-width: 150px;
  }

  .MD-Playlist-CoverImage {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin-left: 60px;
  }

  #MD-Main-Wapper {
    position: absolute;
    right: -250px;
  }

  .MD-playlist-Title {
    color: rgb(238, 238, 238);
    font-size: 12px;
    font-weight: 300;
    width: fit-content;
    margin-left: 60px;
    max-width: 130px;
  }

  .MD-Playlist-Icon {
    position: absolute;
    padding-top: 10px;
    right: 65px;
  }

  .MD-Album-Title {
    color: rgb(238, 238, 238);
    font-size: 12px;
    font-weight: 300;
    width: fit-content;
    margin-left: 60px;
  }

  #MD-eclipse-playlist {
    /* padding-top: 15px; */
    padding-left: 10px;
    color: rgb(226, 96, 106)
  }

  #MD-eclipse-playlist:hover {
    cursor: pointer;
  }


  .TTM-Main-Wrapper {
    background-color: rgb(30, 30, 30);
    border: solid rgb(75, 75, 75) 1px;
    border-radius: 8px;
    width: 110px;
    color: rgb(214, 214, 214);
    font-size: small;
    position: absolute;
    top: 100px;
    left: 100px;
  }

  .UDM-Main-Wrapper {
    background-color: rgb(30, 30, 30);
    /* background-color: red; */
    border: solid rgb(75, 75, 75) 1px;
    border-radius: 8px;
    width: 80px;
    color: rgb(214, 214, 214);
    font-size: small;
    position: absolute;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* used to fix overflow issue with menu popup being hidden by swiper */
  div.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    overflow: none;
  }

  /* idk rn the menu is going under songs and chaning the z index doesnt do anything */
  /* img.MD-song-images{
    z-index: -1;
    overflow: visible;
  } */

  .UDM-Main-Wrapper-Hidden {
    display: none;
  }

  .UDM-Update-Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85px;
    /* border-bottom: solid rgb(98, 98, 98) 1px; */
    cursor: pointer;
    /* justify-content: space-between; */
    padding: 5px 0 5px 0;
    margin-left: 15px;
  }

  .UDM-Delete-Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85px;
    cursor: pointer;
    padding: 5px 0 5px 0;
    margin-left: 15px;
  }



  #update-ico {
    position: relative;
    left: 9.5px;
  }

  #delete-ico {
    position: relative;
    left: 9.5px;
  }

}

@media only screen and (max-width: 768px) {
  .UDM-Main-Wrapper {
    background-color: rgb(30, 30, 30);
    border: solid rgb(75, 75, 75) 1px;
    border-radius: 8px;
    width: 80px;
    color: rgb(214, 214, 214);
    font-size: small;
    position: absolute;
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}