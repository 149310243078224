.SignUp-Wrapper {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.SignUp-Title {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    color: rgba(245, 245, 247, 1);
    padding-left: 10px;
}

.SignUp-Errors {
    /* errors wrapper */
    color: red;
}

.SignUp-Error {
    /* individual errors */
    color: red;
}

.SignUp-Form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
    color: rgba(245, 245, 247, 1);
}

.SignUp-Form-Top-Text {
    display: flex;
    flex-direction: column;
}

#x {
    /* Close icon styles go here */
    position: absolute;
    top: 0;
    right: 0;
    font-size: x-large;
    padding: 15px;
    cursor: pointer;
    color: rgba(245, 245, 247, 1);
}

input {
    background-color: rgb(75, 75, 75);
    padding: 3px 0 3px 0;
    border-radius: 5px;
    border: 2px solid transparent;
    color: white;
}

.SignUp-Buttons-Div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    padding-top: 10px;
}

.SignUp-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: rgb(218, 15, 72);
    width: 60%;
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    border: 0 solid;
    color: white;
}

.SignUp-Button:hover {
    cursor: pointer;
    box-shadow: 5px 5px 5px black;
}

.SignUp-Demo-Btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SignUp-Demo-Btn>p:hover {
    cursor: pointer;
    color: rgb(218, 15, 72);
}


@media only screen and (max-width: 1050px) {
    .SignUp-Wrapper {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
    }

    .SignUp-Title {
        display: flex;
        align-self: center;
        justify-content: flex-start;
        color: rgba(245, 245, 247, 1);
        padding-left: 10px;
    }

    .SignUp-Errors {
        /* errors wrapper */
        color: red;
    }

    .SignUp-Error {
        /* individual errors */
        color: red;
    }

    .SignUp-Form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 70%;
        color: rgba(245, 245, 247, 1);
    }

    .SignUp-Form-Top-Text {
        display: flex;
        flex-direction: column;
    }

    #x {
        /* Close icon styles go here */
        position: absolute;
        top: 0;
        right: 0;
        font-size: x-large;
        padding: 15px;
        cursor: pointer;
        color: rgba(245, 245, 247, 1);
    }

    input {
        background-color: rgb(75, 75, 75);
        padding: 3px 0 3px 0;
        border-radius: 5px;
        border: 2px solid transparent;
        color: white;
    }

    .SignUp-Buttons-Div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        padding-top: 10px;
    }

    .SignUp-Button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        background-color: rgb(218, 15, 72);
        width: 60%;
        border-radius: 4px;
        padding: 8px 0px 8px 0px;
        border: 0 solid;
        color: white;
    }

    .SignUp-Button:hover {
        cursor: pointer;
        box-shadow: 5px 5px 5px black;
    }

    .SignUp-Demo-Btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .SignUp-Demo-Btn>p:hover {
        cursor: pointer;
        color: rgb(218, 15, 72);
    }

    .SignUp-Form-Top-Text{
        margin-bottom: 10px;
    }
}