.AL-Body {
  z-index: -1;
  height: 2000px;
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
}

.AL-labels {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(42, 42, 42);
}
