.US-Main-Wrapper{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    gap: 20px;
    border-radius: 5px;
    padding: 10px 0 10px 0;
    margin: 0 30px 0 30px;
    align-items: center;
}

#x {
    position: absolute;
    top: 25px;
    right: 25px;
}


.US-Title{
    display: flex;
    align-self: center;
    color: white;
    font-size: 20px;
}
.US-input-field{
    padding-top: 0;
    margin-top: 0;
    color: white;
}
.US-Genre{
    width: 90vw;
}
.US-TitleInput {
    height: 25px;
    width: 90vw;
    margin-bottom: 0;
    padding-bottom: 0;
}

.US-update-info {
    display: flex;
}
.US-Submit-Button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: rgb(218, 15, 72);
    /* border: 1px solid white; */
    width: 60%;
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    color: white;
    cursor: pointer;
}
