.M-song-body {
  /* padding-top: 75px;
  padding-left:  280px; */
  z-index: -1;
  min-height: calc(100% + 10px);
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
}

.M-song-section {
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-top: 1.5px solid rgb(42, 42, 42);
  border-bottom: 1.5px solid rgb(42, 42, 42);
}

.M-song-sec-div {
  display: inline-flex;
  flex-direction: row;
  width: 250px;
}

.M-song-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 20px;
  margin-left: 50px;
}

.M-SG-labels {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(42, 42, 42);
}

.M-art-cover {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 10px;
}


.M-song-info {
  color: rgb(238, 238, 238);
  flex-direction: row;
  padding-top: 2px;
  padding-left: 5px;
}

#M-artists-name {
  color: rgb(105, 105, 105);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin: 0px;
}

#M-song-name {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  padding-bottom: 2px;
  margin: 0px;
}

.M-SG-Title {
  color: white;
  font-size: 34px;
}

.M-icon-section {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

#M-song-icon-menu {
  display: inline-flex;
  color: rgb(226 96 106);
  padding-left: 80px;
  padding-right: 10px;
}

#M-song-icon-heart {
  color: rgb(226 96 106);
}

@media only screen and (max-width: 1350px) {
  .M-song-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1050px) {
  .M-song-body {
    /* padding-top: 75px;
    padding-left:  280px; */
    z-index: -1;
    min-height: calc(100% + 10px);
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
  }

  .M-SG-labels {
    padding-bottom: 0px;
    padding-left: 5px;
  }

  .M-song-div {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 0px;
    margin-left: 0px;
  }

  .M-song-section {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 5px;
    border-top: none;
    border-bottom: 1.5px solid rgb(42, 42, 42);
    cursor: pointer;
    width: 100vw;
  }
  
  .M-song-sec-div {
    display: inline-flex;
    flex-direction: row;
    width:fit-content;
    margin-right: 0px;
    overflow: hidden;
  }
}

.song-body {
  /* padding-top: 75px;
  padding-left:  280px; */
  z-index: -1;
  height: 2000px;
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
}

.song-section {
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-top: 1.5px solid rgb(42, 42, 42);
  border-bottom: 1.5px solid rgb(42, 42, 42);
}

.song-sec-div {
  display: inline-flex;
  flex-direction: row;
  width: 250px;
}

.song-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 20px;
  margin-left: 50px;
}

@media only screen and (max-width: 1350px) {
  .song-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
  }
  .M-song-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .song-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
  }
  .M-song-div {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    padding-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1050px) {
  .song-div {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 20px;
    margin: 0;
  }
  .M-song-div {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 0px;
    margin-left: 0px;
  }
}

.SG-labels {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(42, 42, 42);
}

.art-cover {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 10px;
}


.song-info {
  color: rgb(238, 238, 238);
  flex-direction: row;
  padding-top: 2px;
  padding-left: 5px;
}

#artists-name {
  color: rgb(105,105,105);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin: 0px;
}

#song-name {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  padding-bottom: 2px;
  margin: 0px;
}

.SG-Title {
  color: white;
  font-size: 34px;
}

.icon-section {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

#song-icon-menu {
  display: inline-flex;
  color: rgb(226 96 106);
  padding-left: 80px;
  padding-right: 10px;
}

#song-icon-heart {
  color: rgb(226 96 106);
}