.HP-Body {
  /* Set height to 100% of viewport height */
  width: 100vw;
  height: 100%;
  /* Set width to 100% of parent element */
  /* max-height: 1700px; */
  /* Set maximum height to 800 pixels */
  overflow-x: hidden;
}

.HP-Wrapper {
  display: flex;
  justify-content: center;
  height: 54px;
}

.HP-Main-Wrapper {
  /* display: grid; */
}

.HP-Nav {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  /* background-color: yellow; */
}

.HP-GoTo-Button {
  display: flex;
  background-color: rgb(250, 35, 61);
  color: white;
  height: 24px;
  width: 125px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 600;
  font-size: .7em;
  margin-right:15px;
}

.HP-Title {
  display: flex;
  font-size: 1.3em;
}

.HP-TitleAndLogo {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
}

.HP-Pear-Logo-Black {
  display: flex;
  height: 20px;
}

.HP-Apple-Subscription-Wrapper {
  position: relative;
  width: 100%;
  height: 54px;
  overflow: hidden;
}

.HP-Apple-Subscription-Red,
.HP-Apple-Subscription-White {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(250, 35, 61);
  color: white;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.HP-Apple-Subscription-Red {
  opacity: 1;
  transform: translateY(0);
  font-weight: 400;
  font-size: .9em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HP-Apple-Subscription-White {
  opacity: 0;
  background-color: rgb(245, 245, 247);
  color: black;
  font-weight: 300;
  font-size: .9em;
  transform: translateY(100%);
  transition-delay: 0s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 3px;
}

.HP-MeetTheDevs-Arrow{
  height: 20px;
  opacity: .3;
}


@keyframes contentMoveUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}



.HP-Icon-Wrapper-Wrapper {
  display: flex;
  height: 100px;
  width: 100vw;
  overflow: visible;
  position: relative;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 20px;
  /* Add margin: 0 auto; to horizontally center the element */
}

.HP-Icon-Wrapper {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  position: relative;
  top: 0px;
  left: 0;
  /* Optional: Adjust the left position if necessary */
  right: 0;
  /* Optional: Adjust the right position if necessary */
  animation: shrink 4.5s forwards;
}

.HP-Icon {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
}

@keyframes shrink {
  0% {
    transform: scale(1.2) translate(0%, 100%);
  }

  50% {
    transform: scale() translate();
  }

  100% {
    transform: scale(0.27) translate(0, 0px);
  }
}



.HP-Bottom-Text-Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  font-size: 1.4em;
  font-weight: 500;
}



.HP-Headline-Wrapper {
  display: flex;
  justify-content: center;
  height: fit-content;
  padding-top: 10px;
}

.HP-Headline {
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
  font-size: 7em;
  font-weight: 500;
  position: relative;

  margin: 0;
}

@media only screen and (max-width: 1090px) {
  .HP-Headline {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    font-size: 5em;
    font-weight: 500;
    position: relative
  }
}

@media only screen and (max-width: 780px) {
  .HP-Headline {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    font-size: 4em;
    font-weight: 500;
    position: relative;
  }
}

@media only screen and (max-width: 630px) {
  .HP-Headline {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    font-size: 3em;
    font-weight: 500;
    position: relative;
  }
}

@media only screen and (max-width: 500px) {
  .HP-Headline {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    font-size: 2em;
    font-weight: 500;
    position: relative;
  }
}

.HP-Other-Wrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.HP-Other-NavBtn {
  display: flex;
  background-color: rgb(250, 35, 61);
  color: white;
  height: 44px;
  width: 155px;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 600;
  font-size: .9em;
}

.HP-PhoneImgs-Wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 500px;
  /* set the desired height of the container */
  overflow: visible;
  /* hide overflowing content */
  align-items: center;
  justify-content: space-around;
  animation: moveUp 6s ease-in-out;
  z-index: -100;

}

.HP-Image {
  z-index: 1;
  opacity: .9;
  filter: drop-shadow(0 0 1.2rem black);
}

@keyframes moveUp {
  0% {
    transform: translate(2000px, 0px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 880px) {
  .HP-PhoneImgs-Wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 60px;
    height: 50vw;
    /* set the desired height of the container */
    overflow: visible;
    /* hide overflowing content */
    align-items: center;
    justify-content: space-around;
    animation: moveUp 6s ease-in-out;
    z-index: -100;
  }

  .HP-Image {
    z-index: 1;
    opacity: .9;
    height: 50vw;
    filter: drop-shadow(0 0 1.2rem black);
  }
}

.HP-Devs-Wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-evenly;
  transform: scale(.8);
}

@media only screen and (max-width: 1150px) {
  .HP-Devs-Wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-evenly;
    transform: scale(.7);
    width: 100vw;
  }
}

@media only screen and (max-width: 800px) {
  .HP-Devs-Wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    bottom: 200px;
  }
}

.HP-Mikey-Wrapper {
  display: flex;
  overflow: hidden;
  border: 1px solid black;
  width: 367px;
  height: 360px;
  border-radius: 40px;
  background-image: url('../../images/Dev-Background/haku.png');
  background-size: cover;
  /* Adjust the image size to cover the entire element */
  background-repeat: no-repeat;
  /* Prevent the image from repeating */
  background-position: right 0px top 0px;
}

.HP-Yasiney-Wrapper {
  display: flex;
  overflow: hidden;
  border: 1px solid black;
  width: 367px;
  height: 360px;
  border-radius: 40px;
  background-image: url('../../images/Dev-Background/kiki-2.jpeg');
  background-size: cover;
  /* Adjust the image size to cover the entire element */
  background-repeat: no-repeat;
  /* Prevent the image from repeating */
  background-position: center center;
}

.HP-Sadiqey-Wrapper {
  display: flex;
  overflow: hidden;
  border: 1px solid black;
  width: 367px;
  height: 360px;
  border-radius: 40px;
  background-image: url('../../images/Dev-Background/purple.png');
  background-size: cover;
  /* Adjust the image size to cover the entire element */
  background-repeat: no-repeat;
  /* Prevent the image from repeating */
  background-position: center center;
}

.HP-Sadiqey-Wrapper:hover {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  animation: grow-shrink 4s infinite;
}

.HP-Mikey-Wrapper:hover {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  animation: grow-shrink 4s infinite;
}

.HP-Yasiney-Wrapper:hover {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  animation: grow-shrink 4s infinite;
}

@keyframes grow-shrink {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }
}

.HP-Mikey {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  position: relative;
  right: 0px;
  top: 95px;
}

.HP-Yasiney {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  transform: scale(1.2);
  position: relative;
  right: 160px;
}

.HP-Sadiqey {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .5));
  position: relative;
  top: 140px;
  left: 140px;
}

.HP-ImgAndIcons-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px
}

.HP-Dev-Name-Wrapper {
  text-align: center;
  width: 360px;
}

.HP-Dev-Name {
  font-size: 1.8em;
}

.HP-Github {
  display: flex;
  background-color: rgb(250, 35, 61);
  color: white;
  height: 44px;
  width: 155px;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 1.3em;
}

.HP-LinkedIn {
  display: flex;
  background-color: rgb(250, 35, 61);
  color: white;
  height: 44px;
  width: 155px;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 1.3em;
}