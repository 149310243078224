/* IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE*/
.SI-Wrapper {
    height: 100%;
    width: 95vw;
    padding: 0px 10px 100px 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.SI-Title {
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.SI-Search-Field-Wrapper {}

.SI-InputBox-Wrapper {
    display: flex;
    flex-direction: row;
}

#SI-SearchIcon {
    color: black;
    position: absolute;
    font-size: 1.8em;
    left: 20px;
    top: 86px;
}

.SI-Search-Input {
    background-color: white;
    color: black;
    width: 100%;
    height: 30px;
    padding-left: 40px;
    font-size: .95em;
    cursor: pointer;
}

.SI-BrowseAll {
    color: white;
    font-weight: 300;
}

.SI-DiscoverGenreBlocks-Wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.SI-GenreBlock {
    display: flex;
    text-align: center;
    font-size: 25px;
    width: 47%;
    height: 70px;
    padding-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    border-radius: 5px;
    opacity: .9;
    margin-bottom: 15px;
    cursor: pointer;
}

.SI-DiscoverGenreBlocks-Wrapper> :nth-child(2n) {
    margin-left: 15px;
}

.SI-GenreBlock-Text {
    position: relative;
    bottom: 35px;
    left: 7px;
    font-size: .9em;
    color: white;
    font-weight: 300;
}

/* IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE IN-ACTIVE*/











/* ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE*/
.SA-Wrapper {
    height: 100%;
    width: 95vw;
    padding: 70px 10px 100px 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.SA-Search-Field-Wrapper {
    display: flex;
}

#SA-SearchIcon {
    color: black;
    position: absolute;
    font-size: 1.8em;
    left: 71px;
    top: 77px;
}

#SA-Arrow {
    color: white;
    width: 40px;
    height: 41px;
    /* background-color: red; */
    font-size: 1.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.SA-InputBox-Wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
}

.SA-Search-Input {
    background-color: white;
    color: black;
    width: 100%;
    height: 30px;
    padding-left: 40px;
    font-size: .95em;
}

.SA-Search-Results-Wrapper {
    display: flex;
    /* background-color: red; */
    width: 100%;
}

.SA-Search-Results {
    /* background-color: yellow; */
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
}

.SA-Search-Result {
    padding: 10px 0 10px 0px;
    list-style-type: none;
    margin: 0;
    /* border-bottom: 1px solid black; */
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.SA-Search-Img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 40px;
    margin:10px 20px 0 40px;
}

.SA-TitlenType-Wrapper{
    display: inline-flex;
    flex-direction: column;
    height: fit-content;
    
}
.SA-SongTitle{
    margin: 0;
}

.SA-Type{
    font-size: .8em;
    opacity: .8;
    margin: 0;
    position: relative;
    top: 10px;
}
/* ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE ACTIVE*/