.PL-Body {
  z-index: -1;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 65px;
  padding-left: 300px;
  padding-right: 50px;
}

.PL-Body-Title {
  color: rgb(238, 238, 238);
  font-size: 34px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(42, 42, 42);
}

.PL-Section {
  display: inline-flex;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 40px;
}

.PL-Div {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 0px 10px;
}

.PL-Title {
  color: white;
  font-size: 10.5px;
}

.PL-CoverImg {
  display: flex;
}

.PL-Card-Wrapper {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.PL-Img {
  height: 225px;
  width: 225px;
  border-radius: 10px;
  transition: .2s ease-in;
}

#test:hover {
  box-shadow: 10px 10px 10px black;
  opacity: 0.7;
}

.PL-Img:hover {
  box-shadow: 3px 3px 3px black;
}

.PL-Bottom-Sec {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.icons-sec {
  padding-top: 5px;
}

#PL-icon-heart {
  display: inline-flex;
  color: rgb(226 96 106);
  /* padding-left: 15px; */
  padding-right: 10px;
  padding-top: 8px;

}

#PL-icon-menu {
  color: rgb(226 96 106);
}

@media only screen and (max-width: 1050px) {
  .PL-Body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: -1;
    height: 100%;
    padding-bottom: 100px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .PL-Body-Title {
    color: rgb(238, 238, 238);
    font-size: 34px;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(42, 42, 42);
  }

  .PL-Card-Wrapper {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .PL-Section {}

  .PL-Div {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 10px;
  }

  .PL-Title {
    color: white;
    font-size: 10.5px;
  }

  .PL-CoverImg {
    display: flex;
  }

  .PL-Img {
    height: 225px;
    width: 225px;
    border-radius: 10px;
    transition: .2s ease-in;
  }

  #test:hover {
    box-shadow: 10px 10px 10px black;
    opacity: 0.7;
  }

  .PL-Img:hover {
    box-shadow: 3px 3px 3px black;
  }

  .PL-Bottom-Sec {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  .icons-sec {
    padding-top: 5px;
  }

  #PL-icon-heart {
    display: inline-flex;
    color: rgb(226 96 106);
    padding-right: 10px;
    padding-top: 8px;

  }

  #PL-icon-menu {
    color: rgb(226 96 106);
  }
}