body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  margin: 0;
  height: 100vh;
  background-color: rgb(31, 31, 31);

}

.ERR{
  color: white;
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 1em;
}

.LoadingGIf{
  position: absolute;
  width: 30px;
  top: 50%;
  left: 60%;
}

@media only screen and (max-width: 1050px) {
  .ERR {
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1em;
  }
  .LoadingGIf{
    position: absolute;
    width: 30px;
    top: 50%;
    left: 50%;
  }
}



/* right side webpage scroll bar css made it look a lil btr */

/* body::-webkit-scrollbar {
  width: .45em;
  height: 120px;
}

body::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
} */
