.EG-Body {
  z-index: -1;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-left: 300px;
  padding-right: 50px;
}

@media only screen and (max-width: 1050px) {
  .EG-Body {
    z-index: -1;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

      