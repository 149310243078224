



.SB-body {
  display: flex;
  float: left;
  flex-direction: column;
  width: min-content;
  position: fixed;
  border-right: 1px solid rgb(59, 59, 60);
  padding-left: 20px;
  padding-right: 65px;
  height: 100vh;
  background-color: rgb(37, 37, 38);
  color: white;
  font-weight: 300;
  z-index: 100;
}

.SB-search-field-div {
  position: relative;
}

.SB-search-field-div input {
  padding-left: 30px;
}

.SB-search-field-div span {
  position: absolute;
  left: 7px;
  top: 4px;
  font-size: 20px;
}

.SB-Search-Results{
  height: fit-content;
  width: 178px;
  list-style-type: none;
  padding: 0;
  font-size: .9em;
  margin-top:0;
  padding-top:0;
  text-align: center;
  border: 1px solid grey;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position:absolute;
}

.SB-Search-Result{
  padding: 3px 0px 3px 0px;
  background-color: rgba(76, 76, 76, .9);
}

.SB-Search-Result:not(:first-child){
  border-top:1px solid rgb(64, 64, 64);
}

.SB-Search-Result:hover{
  cursor: pointer;
  background-color: rgba(199,96,105,1);
}

.SB-icons {
  align-items: center;
  color: rgb(226 96 106);
}

#search-field {
  background-color: rgb(31, 31, 31);
  color: white;
  border: 1px solid rgb(64, 64, 64);
  border-radius: 3px;
  padding: 6px 5px 5px 28px;
}

#search-field:focus {
  box-shadow: 0px 0px 0px 3px rgb(176 76 78);
  outline: none;
}

.SB-Library {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  list-style-type: none;
}

div.SB-Library {
  display: flex;
  flex-direction: column;
}

.pink-icons {
  padding-right: 7px;
  padding-top: 14px;
}

.SB-lib-play-headers {
  font-weight: 400;
  color: rgb(110, 110, 108);
  margin-bottom: 5px;
}

.SB-User-Playlist-li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.SB-Playlist-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  list-style-type: none;
  padding-bottom: 7px;
}

.SB-user-single-playlist {
  color: white;
  font-size: 12px;
  font-weight: 300;
  margin-top: 20px;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1050px) {
  .SB-body{
    display: none;
    width: 0;
    height: 0;
    z-index: -1;
  }
}